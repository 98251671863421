import { isArray } from "lodash";
import { isNumber } from "./common";

class CssCore {
  constructor() {
    this.initCreateStyleSheet();
  }

  initCreateStyleSheet() {
    if(typeof (document as any).createStyleSheet === 'undefined') {
      (document as any).createStyleSheet = (function() {
        function createStyleSheet(href: string) {
          let element: any;
            if(typeof href !== 'undefined') {
                element = document.createElement('link');
                element.type = 'text/css';
                element.rel = 'stylesheet';
                element.href = href;
            }
            else {
                element = document.createElement('style');
            }
            
            document.getElementsByTagName('head')[0].appendChild(element);
            const sheet: any = element.sheet;

            sheet.insertCssRule = insertRule;

            (StyleSheet.prototype as any).insertCssRule = insertRule;

            if(typeof sheet.addRule === 'undefined') {
              sheet.addRule = addRule;
              (StyleSheet.prototype as any).addRule = addRule;
            }
                
            if(typeof sheet.removeRule === 'undefined') {
              sheet.removeRule = sheet.deleteRule;
                (StyleSheet.prototype as any).removeRule = sheet.deleteRule;
            }

            return {sheet, element};
        }

        function insertRule(cssText: string, index: number) {
          // @ts-ignore
          if(typeof index === 'undefined') index = this.cssRules.length;
          // @ts-ignore
          this.insertRule(cssText, index);
        }

        function addRule(selectorText: string, cssText: string, index: number) {
          const css = selectorText + ' {' + cssText + '}';
          // @ts-ignore
          insertRule(css, index);
        }
  
          return createStyleSheet;
      })();
    }
  }

  createClassName(baseName?: string) {
    const str = Math.random().toString(36).substring(7);
    return `${baseName || 'css'}_${str}`;
  }

  getCssRule(sheet: any, className: string) {
    const cssRules = Object.values(sheet.cssRules);
    const rule = cssRules.find((item: any) => item.selectorText === className);
    return rule;
  } 

  getCssLengthUnit(str: string) {
    let unit = '', index = str.length - 1;
    while(index >= 0) {
      const i = str[index--];
      if (i === '.' || isNumber(i)) return unit;
      unit = i + unit;
    }
    return unit;
  }

  getDom(className: string) {
    return document.querySelectorAll(`.${className}`)[0] || document.getElementsByClassName(className)[0];
  }

  camelize(str: string) {
    return str.replace(/-(\w)/g, (_, letter) => letter.toUpperCase());
  };

  getStyle(className: string, styleNames: string | string[]) {
    const dom: any = this.getDom(className);
    if (!dom || !styleNames?.length) return '';
    let rect: any;
    const clientRect: any = dom.getBoundingClientRect();
    if (dom?.currentStyle) {
      rect = dom?.currentStyle;
    } else if (document.defaultView && document.defaultView.getComputedStyle) {
      rect = document.defaultView.getComputedStyle(dom,null);
    } else {
      rect = dom.style; 
    }
    if (isArray(styleNames)) {
      return styleNames.reduce((total: any, item: string) => {
        total[item] = (clientRect[item] ? clientRect[item] + 'px' : rect[this.camelize(item)]) || '';
        return total;
      }, {})
    }
    return (clientRect[styleNames] ? clientRect[styleNames] + 'px' : rect[this.camelize(styleNames)]) || '';
  }

  transformCssText(style: {[key: string]: any}, className: string) {
    const cssText = (Object.entries(style) || []).reduce((total: string, item) => {
      total += `${item[0]}: ${item[1]};`
      return total;
    }, '');
    return `.${className} {${cssText}}`;
  }

  insertCssRule(cssText: string, className: string, styleSheet: any, styleDom?: any) {
    if (!cssText) return;
    const cssRules = Object.values(styleSheet.cssRules);
    const index = cssRules.findIndex((item: any) => item.selectorText === className);
    index > -1 && styleSheet.removeRule(index);
    styleSheet.insertCssRule(cssText);
    if (styleDom) {
      styleDom.innerHTML = (Object.values(styleSheet.cssRules) || []).reduce((total: string, item: any) => total + item.cssText, '')
    }
  }

  replaceCssTextItem(cssText: string, style: {[key: string]: string}) {
    let strArr = cssText.split('{').map(item => item.trim());
    const className = strArr[0];
    strArr = strArr[1].split('}');
    const styleStr = strArr[0];
    const styleArr = styleStr.split(';').map(item => item.trim()).filter(Boolean);
    let styleMap: any = styleArr.reduce((total, item) => {
      const [n, v] = item.split(': ').map(item => item.trim());
      total = {
        ...total,
        [n]: v
      };
      return total;
    }, {});
    styleMap = { ...styleMap, ...style };
    return this.transformCssText(styleMap, className.slice(1));
  }

  formatClassName(className: string, status: string) {
    if (status === 'default') return `.${className}`;
    return `.${className}:${status}`;
  }

  ruleStyle(rule: CSSStyleRule, prefix: string) {
    const rootSelectorRE = /((?:[^\w\-.#]|^)(body|html|:root))/gm;
    const rootCombinationRE = /(html[^\w{[]+)/gm;

    const selector = rule.selectorText.trim();

    let { cssText } = rule;
    if (selector && (selector[0] === '.' || selector[0] === '#')) return cssText;
    // handle html { ... }
    // handle body { ... }
    // handle :root { ... }
    if (selector === 'html' || selector === 'body' || selector === ':root') {
      return cssText.replace(rootSelectorRE, prefix);
    }

    // handle html body { ... }
    // handle html > body { ... }
    if (rootCombinationRE.test(rule.selectorText)) {
      const siblingSelectorRE = /(html[^\w{]+)(\+|~)/gm;

      // since html + body is a non-standard rule for html
      // transformer will ignore it
      if (!siblingSelectorRE.test(rule.selectorText)) {
        cssText = cssText.replace(rootCombinationRE, '');
      }
    }

    // handle grouping selector, a,span,p,div { ... }
    cssText = cssText.replace(/^[\s\S]+{/, (selectors) =>
      selectors.replace(/(^|,\n?)([^,]+)/g, (item, p, s) => {
        // handle div,body,span { ... }
        if (rootSelectorRE.test(item)) {
          return item.replace(rootSelectorRE, (m) => {
            // do not discard valid previous character, such as body,html or *:not(:root)
            const whitePrevChars = [',', '('];

            if (m && whitePrevChars.includes(m[0])) {
              return `${m[0]}${prefix}`;
            }

            // replace root selector with prefix
            return prefix;
          });
        }

        return `${p}${prefix} ${s.replace(/^ */, '')}`;
      }),
    );

    return cssText;
  }

  getPosition(className: string, rootId?: string) {
    let dom: any = this.getDom(className);
    const root = rootId ? (document.getElementById(rootId) ?? document.body) : document.body;
    if (!dom) return;
    let x = 0, y = 0;
    while (dom !== root) {
      x += dom.offsetLeft;
      y += dom.offsetTop;
      dom = dom.parentNode;
    }
    return {x, y}
  }

}

const cssCore = new CssCore();

export default cssCore;