import { Select } from 'antd';
import React, { useMemo, useState } from 'react';
import SettingsGroup from '../settingsGroup';
import settingFormItemHoc from '../settingFormItemHoc';
import cssCore from 'src/pages/build/utils/cssCore';
import SizeSetting from '../sizeSetting';
import ColorPicker from '../colorPicker';
import { BORDER_LIST, BORDER_STYLE, BORDER_STYLE_LIST, BORDER_TYPE, Props } from './common';
import '../../index.less';

const { Option } = Select;

const Border = (props: Omit<Props, 'onChange'>) => {
  const { meta, cssRule } = props;
  const [type, setType] = useState<BORDER_TYPE>(BORDER_TYPE.all)
  return (
    <SettingsGroup title='边框'>
      <BorderType value={type} onChange={(val: BORDER_TYPE) => setType(val)}/>
      <BorderStyle meta={meta} cssRule={cssRule} type={type === BORDER_TYPE.all ? 'border-style' : `border-${type}-style`}/>
      <SizeSetting cssRule={cssRule} meta={meta} type={type === BORDER_TYPE.all ? 'border-width' : `border-${type}-width`}/>
      <ColorPicker title='颜色' type={type === BORDER_TYPE.all ? 'border-color' : `border-${type}-color`} cssRule={cssRule} meta={meta}/>
    </SettingsGroup>
  );
}

const BorderType = (props: {value: BORDER_TYPE, onChange: any}) => {
  const { value, onChange } = props;
  return (
    <div className='propsform-item-container'>
      <div className='propsform-item-title'>选择边框</div>
      <Select
        className='propsform-item-input'
        value={value}
        onChange={onChange}
      >
        {
          BORDER_LIST.map((item: {key: BORDER_TYPE, title: string}) => (
            <Option key={item.key}>{item.title}</Option>
          ))
        }
      </Select>
    </div>
  );
}

const BorderStyle = settingFormItemHoc((props: Props & { type: string }) => {
  const { cssRule, onChange, type } = props;
  const value = useMemo(() => cssRule?.style?.[cssCore.camelize(type)], [cssRule, type]);
  return (
    <div className='propsform-item-container'>
      <div className='propsform-item-title'>线形</div>
      <Select
        className='propsform-item-input'
        value={value}
        onChange={(val: BORDER_STYLE) => onChange(type, val)}
      >
        {
          BORDER_STYLE_LIST.map((item: {key: BORDER_STYLE, title: string}) => (
            <Option key={item.key}>{item.title}</Option>
          ))
        }
      </Select>
    </div>
  );
})

export default Border;