import React from 'react';
import { Input } from 'antd';
import '../../index.less';


interface Props {
	value: string;
	onChange: any;
  title: string;
	placeholder?: string;
};

const InputSetting = (props: Props) => {
	const { onChange, value, title, placeholder } = props;

	return (
		<div className='propsform-item-container'>
			<div className='propsform-item-title'>{title}</div>
			<Input value={value} onChange={(e: any) => onChange(e.target.value)}  placeholder={placeholder ?? `请输入${title}`}/>
		</div>
	);
};

export default InputSetting;
