import React, { useEffect, useMemo } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../model';
import './index.less';

const { TabPane } = Tabs;

const PropsForm = () => {
  const dispatch = useDispatch<Dispatch>();
  const { activeWidget, widgets, pageMetaMap } = useSelector((state: RootState) => {
    return state.page;
  });
  const meta = useMemo(() => pageMetaMap[activeWidget], [pageMetaMap, activeWidget]);

  const { PropsForm } = widgets?.[meta?.widgetType] || {};

  const {AdvancedSettings, BaseSettings, StyleSettings} = PropsForm || {};

  useEffect(() => {
    const clearActive = (e: any) => dispatch.page.setState({activeWidget: ''});
    document.addEventListener('click', clearActive);
    return () => {
      document.removeEventListener('click', clearActive);
    };
  }, [dispatch]);

  const handleStopPropagation = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  if (!PropsForm) return null;
  
  return (
    <div className='props-form' onClick={handleStopPropagation}>
      <Tabs defaultActiveKey="2" className='tab-container' tabPosition='top'>
        {BaseSettings && (
          <TabPane key='1' tab='属性'>
            <div className='propsform-container'><BaseSettings meta={meta} /></div>
          </TabPane>
        )}
        {StyleSettings && (
          <TabPane key='2' tab='样式'>
             <div className='propsform-container'><StyleSettings meta={meta}/></div>
          </TabPane>
        )}
        {AdvancedSettings && (
          <TabPane key='3' tab='高级'>
             <div className='propsform-container'><AdvancedSettings meta={meta} /></div>
          </TabPane>
        )}
      </Tabs>
    </div>
  );
}

export default PropsForm;
