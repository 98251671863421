import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import style from './index.module.less';
import { RootState } from '../../model';
import { formatPageMetaData } from '../../utils/meta';

const BuildHeader = () => {
  const navigate = useNavigate();
  // const { routers } = useSelector((state: RootState) => {
  //   return state.router;
  // });
  const { pageMetaMap } = useSelector((state: RootState) => {
    return state.page;
  });

  const handlePriview = () => {
    // navigate('/render', { 
    //   state: { 
    //     metaMap: {
    //       routers
    //     }
    //   } 
    // });
    formatPageMetaData(pageMetaMap);
    const getCssCode = (data: any) => {
      const values = Object.values(data || {});
      if (!values.length) return '';
      const cssCode = values.reduce((total, item: any) => {
        const { styleSheet = {} } = item;
        const { cssRules = [] } = styleSheet;
        const cssStr = [...cssRules].reduce((t: string, i: any) => t + (i?.cssText || ''), '');
        return total + cssStr;
      }, '');
      return cssCode
    }
    const css = getCssCode(pageMetaMap);
    console.log(css);
  };

  return (
    <div className={style.container}>
      <div className={style['header-title']}>动态配置平台</div>
      <div className={style['right-buttons']}>
        <Button size='large' shape="round" onClick={handlePriview}>预览</Button>
        <Button style={{marginLeft: '10px'}} shape="round" type="primary" size='large'>保存</Button>
      </div>
    </div>
  );
};

export default BuildHeader;