import { createModel } from '@rematch/core'
import { RootModel } from './models';

export interface IRouter {
  title: string;
  content: string;
  key: string;
}

interface IState {
  routers: IRouter[];
  activePath: string;
  pageWidth: number,
  pageHeight: number,
}

const router = createModel<RootModel>()({
  state: {
    routers: [
      {title: 'index', key: '/', content: 'index'}
    ],
    activePath: '/',
    pageWidth: 390,
    pageHeight: 844,
  } as IState,
  reducers: {
    add(state: IState, router) {
      if (!router) return state;
      const routers = state.routers;
      return {
        ...state,
        routers: [...routers, router]
      };
    },
    remove(state: IState, path: string) {
      if (!path) return state;
      const routers = state.routers || [];
      const index: number = routers.findIndex(item => item?.key === path);
      const newActivePath = path === state.activePath ? routers[index - 1].key : state.activePath;
      index !== -1 && routers.splice(index, 1);
      return {
        ...state,
        routers: routers.filter(item => item?.key !== path),
        activePath: newActivePath
      };
    },
    activeRouter(state: IState, path: string) {
      return {
        ...state,
        activePath: path,
      };
    }
  },
  effects: (dispatch) => ({
    async addAsync() {
      // const p = new Promise(res => {
      //   setTimeout(res, 3000);
      // });
      // await p;
      // dispatch.router.add();
    }
  })
})

export default router;