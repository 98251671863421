import React from 'react';

export const flexDerictionData = [
	{
		key: 'row',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 19 9" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path d="M16 4h-4v1h4v2l3-2.5L16 2v2zM6 0h5v9H6z"></path>
					<path opacity=".4" d="M1 1h3v7H1z"></path>
					<path d="M1 1v7h3V1H1zM0 0h5v9H0V0z"></path>
				</g>
			</svg>
		),
		desc: 'direction:row'
	},
	{
		key: 'column',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 9 18" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path d="M0 6h9v5H0zm5 9v-3H4v3H2l2.5 3L7 15H5z"></path>
					<path opacity=".4" d="M1 1h7v3H1z"></path>
					<path d="M1 1v3h7V1H1zM0 0h9v5H0V0z"></path>
				</g>
			</svg>
		),
		desc: 'direction:column'
	},
	{
		key: 'row-reverse',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 19 9" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path d="M16 4h-4v1h4v2l3-2.5L16 2v2zM6 0h5v9H6z" data-spm-anchor-id="a2q5o.26736379.0.i15.59bf75ba4nBPSu"></path>
					<path opacity=".4" d="M1 1h3v7H1z"></path>
					<path d="M1 1v7h3V1H1zM0 0h5v9H0V0z"></path>
				</g>
			</svg>
		),
		desc: 'direction:row-reverse'
	},
	{
		key: 'column-reverse',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 9 18" style={{verticalAlign: 'middle'}} data-spm-anchor-id="a2q5o.26736379.0.i16.59bf75ba4nBPSu">
				<g fill-rule="evenodd">
					<path d="M0 6h9v5H0zm5 9v-3H4v3H2l2.5 3L7 15H5z"></path>
					<path opacity=".4" d="M1 1h7v3H1z"></path>
					<path d="M1 1v3h7V1H1zM0 0h9v5H0V0z"></path>
				</g>
			</svg>
		),
		desc: 'direction:column-reverse'
	}
];

export const alignItemsData = [
	{
		key: 'flex-start',
		desc: 'align: flex-start',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 24 17" style={{verticalAlign: 'middle'}} data-spm-anchor-id="a2q5o.26736379.0.i17.59bf75ba4nBPSu">
				<g fill-rule="evenodd">
					<path opacity=".5" d="M0 0h1v17H0z"></path>
					<path d="M2 9h9v5H2z"></path>
					<path opacity=".4" d="M3 4h7v3H3z"></path>
					<path d="M3 4v3h7V4H3zM2 3h9v5H2V3z"></path>
				</g>
			</svg>
		)
	}, 
	{
		key: 'center',
		desc: 'align: center',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 9 17" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path d="M4 0h1v17H4V0zm0 8.5h1v6H4v-6zm0-6h1v6H4v-6z" opacity=".5"></path>
					<path d="M0 9h9v5H0z"></path>
					<path opacity=".4" d="M1 4h7v3H1z"></path>
					<path d="M1 4v3h7V4H1zM0 3h9v5H0V3z"></path>
				</g>
			</svg>
		)
	},
	{
		key: 'flex-end',
		desc: 'align: flex-end',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 24 17" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path opacity=".5" d="M23 0h1v17h-1z"></path>
					<path d="M13 9h9v5h-9z"></path>
					<path opacity=".4" d="M14 4h7v3h-7z"></path>
					<path d="M14 4v3h7V4h-7zm-1-1h9v5h-9V3z"></path>
				</g>
			</svg>
		)
	},
	{
		key: 'stretch',
		desc: 'align: stretch',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 24 17" style={{verticalAlign: 'middle'}} data-spm-anchor-id="a2q5o.26736379.0.i20.59bf75ba4nBPSu">
				<g fill-rule="evenodd">
					<path opacity=".5" d="M23 0h1v17h-1zM0 0h1v17H0z"></path>
					<path d="M2 9h20v5H2z"></path>
					<path opacity=".4" d="M3 4h18v3H3z"></path>
					<path d="M3 4v3h18V4H3zM2 3h20v5H2V3z"></path>
				</g>
			</svg>
		)
	},
	{
		key: 'baseline',
		desc: 'align: baseline',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 24 17" style={{verticalAlign: 'middle'}} data-spm-anchor-id="a2q5o.26736379.0.i21.59bf75ba4nBPSu">
				<g fill-rule="evenodd">
					<path opacity=".5" d="M0 0h1v17H0z"></path>
					<path d="M2 9h9v5H2V9zm1 1h2v3H3v-3z"></path>
					<path opacity=".4" d="M5 4h5v3H5z"></path>
					<path d="M3 4v3h7V4H3zM2 3h9v5H2V3z"></path>
				</g>
			</svg>
		)
	}
]

export const justifyContentData = [
	{
		key: 'flex-start',
		desc: 'justify: flex-start',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 21 18" style={{verticalAlign: 'middle'}} data-spm-anchor-id="a2q5o.26736379.0.i23.59bf75ba4nBPSu">
				<g fill-rule="evenodd">
					<path opacity=".5" d="M0 0h21v1H0z"></path>
					<path d="M6 8h9v5H6z"></path>
					<path opacity=".4" d="M7 3h7v3H7z"></path>
					<path d="M7 3v3h7V3H7zM6 2h9v5H6V2z"></path>
				</g>
			</svg>
		)
	},
	{
		key: 'center',
		desc: 'justify: center',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 21 13" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path opacity=".5" d="M0 6h21v1H0z"></path>
					<path d="M6 8h9v5H6z"></path>
					<path opacity=".4" d="M7 1h7v3H7z"></path>
					<path d="M7 1v3h7V1H7zM6 0h9v5H6V0z"></path>
				</g>
			</svg>
		)
	},
	{
		key: 'flex-end',
		desc: 'justify: flex-end',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 21 18" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path d="M6 11h9v5H6z"></path>
					<path opacity=".4" d="M7 6h7v3H7z"></path>
					<path d="M7 6v3h7V6H7zM6 5h9v5H6V5z"></path>
					<path opacity=".5" d="M0 17h21v1H0z"></path>
				</g>
			</svg>
		)
	},
	{
		key: 'space-between',
		desc: 'justify: space-between',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 21 18" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path opacity=".5" d="M0 0h21v1H0zm0 17h21v1H0z"></path>
					<path d="M6 11h9v5H6z"></path>
					<path opacity=".4" d="M7 3h7v3H7z"></path>
					<path d="M7 3v3h7V3H7zM6 2h9v5H6V2z"></path>
				</g>
			</svg>
		)
	},
	{
		key: 'space-around',
		desc: 'justify: space-around',
		svg: () => (
			<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" width="20" height="20" viewBox="0 0 21 13" style={{verticalAlign: 'middle'}}>
				<g fill-rule="evenodd">
					<path d="M0 2h21v1H0V2zm5.5 0h10v1h-10V2zM0 10h21v1H0v-1zm5.5 0h10v1h-10v-1z" opacity=".5"></path>
					<path d="M6 8h9v5H6z" data-spm-anchor-id="a2q5o.26736379.0.i22.59bf75ba4nBPSu"></path>
					<path opacity=".4" d="M7 1h7v3H7z"></path>
					<path d="M7 1v3h7V1H7zM6 0h9v5H6V0z"></path>
				</g>
			</svg>
		)
	},
];