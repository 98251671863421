import { Meta } from "src/lib/type/meta";

export interface Props {
	meta: Meta;
  onChange: any;
	cssRule: any;
}

export enum BORDER_TYPE {
  all = 'all',
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

export const BORDER_LIST = [
  {
    key: BORDER_TYPE.all,
    title: '全部'
  },
  {
    key: BORDER_TYPE.top,
    title: '上边框'
  },
  {
    key: BORDER_TYPE.right,
    title: '右边框'
  },
  {
    key: BORDER_TYPE.bottom,
    title: '下边框'
  },
  {
    key: BORDER_TYPE.left,
    title: '左边框'
  },
]

export enum BORDER_STYLE {
  none = 'none',
  solid = 'solid',
  dotted = 'dotted',
  dashed = 'dashed'
}

export const BORDER_STYLE_LIST = [
  {
    key: BORDER_STYLE.none,
    title: '无'
  },
  {
    key: BORDER_STYLE.solid,
    title: '实线'
  },
  {
    key: BORDER_STYLE.dotted,
    title: '点线'
  },
  {
    key: BORDER_STYLE.dashed,
    title: '虚线'
  }
]

export enum BORDER_RADIUS_TYPE {
  all = 'all',
  topLeft = 'top-left',
  topRight = 'top-right',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right'
}

export const BORDER_RADIUS_OPTIONS = [
  {
    key: BORDER_RADIUS_TYPE.all,
    title: '全部'
  },
  {
    key: BORDER_RADIUS_TYPE.topLeft,
    title: '左上角'
  },
  {
    key: BORDER_RADIUS_TYPE.topRight,
    title: '右上角'
  },
  {
    key: BORDER_RADIUS_TYPE.bottomLeft,
    title: '左下角'
  },
  {
    key: BORDER_RADIUS_TYPE.bottomRight,
    title: '右下角'
  }
]