import React from 'react';
import { useDispatch } from 'react-redux';
import { Meta } from 'src/lib/type/meta';
import InputSetting from 'src/pages/build/components/propsForm/components/inputSetting';
import { Dispatch } from 'src/pages/build/model';
// import commonStyles from '../../index.module.less';

interface Props {
	meta: Meta;
}

const BaseSettings: React.FC<Props> = (props) => {
	const dispatch = useDispatch<Dispatch>();
	const { meta } = props;
	const handleTextChange = (value: string) => {
		dispatch.page.handleUpdateMeta({
			meta,
			value
		})
	}
	return (
		<div>
			<InputSetting value={meta?.value} title='文本内容' onChange={handleTextChange} />
		</div>
	);
}

export default BaseSettings;