import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
// import { getEmptyImage } from 'react-dnd-html5-backend';
import { Widget } from '../../const/widget';
import { RootState } from '../../model';
import style from './index.module.less';
import { DragType } from '../../const/drag';

const WidgetItem: React.FC<{item: Widget; onClickItem: (e: any, item: Widget) => any}> = ({ item, onClickItem }) => {
  const [{ opacity }, dragRef, preview] = useDrag(
    () => ({
      type: DragType.widget,
      item: () => {
        console.log('拖动开始')
        return item;
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        item,
      }),
      end() {
        console.log('拖动结束')
      }
    }),
    []
  );

  useEffect(() => {
    // preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <div ref={dragRef} style={{ opacity }} className={style['widget-item']} key={item.widgetType}>
      <div className={style['widget-item-title']} onClick={(e) => onClickItem(e, item)}>
        <div className={style['widget-item-cn']}>{item?.title['zh-CN']}</div>
        <div className={style['widget-item-en']}>{item?.title['en-US']}</div>
      </div>
    </div>
  );
}

const WidgetList = () => {
  const { widgetList } = useSelector((state: RootState) => {
    return state.page;
  });

  const handleClickItem = (e: any, item: Widget) => {
    e.nativeEvent.stopImmediatePropagation();
    if (!item) return;
  }
  return (
    <div className={style['widget-list']}>
      <div className={style['widget-list-title']} >基础组件</div>
      {
        widgetList.map((item: Widget) => <WidgetItem item={item} key={item.widgetType} onClickItem={handleClickItem}></WidgetItem>)
      }
    </div>
  );
}

export default WidgetList;
