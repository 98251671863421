import React, { useMemo } from 'react';
import { Select } from 'antd';
import '../../index.less';
import { fontData } from 'src/pages/build/utils/font';
import { Meta } from 'src/lib/type/meta';
import settingFormItemHoc from '../settingFormItemHoc';

const { Option } = Select;

interface Props {
	meta: Meta;
	onChange: any;
	cssRule: any;
};

const FontFamily = settingFormItemHoc((props: Props) => {
  const { onChange, cssRule } = props;
	const value = useMemo(() => cssRule?.style?.fontFamily, [cssRule]);
  const handleChange = (e: string) => {
    onChange('font-family', e)
  };
  return (
    <div className='propsform-item-container'>
			<div className='propsform-item-title'>字体类型</div>
      <Select value={value} className='propsform-item-input' placeholder='请选择字体' onChange={handleChange}>
        {
          fontData.map((item: {key: string, title: string}) => (
            <Option key={item.key} value={item.key}><span style={{fontFamily: item.key}}>{item.title}</span></Option>
          ))
        }
      </Select>
		</div>
  );
})

export default FontFamily;