import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Drawer } from 'antd';
import MonacoEditor from 'react-monaco-editor';
import { Meta } from 'src/lib/type/meta';
import './index.less';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'src/pages/build/model';

const CssCodeEdit = (props: {meta: Meta, sheet: any}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { meta } = props;
  const editorRef = useRef<any>();
  
  const [code, setCode] = useState<string>('');
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (visible) {
      const cssRules = Object.values(meta.styleSheet.cssRules);
      const cssCode = cssRules.reduce((total: string, item: any) => total + item.cssText, '') || `.${meta.className} {}`;
      setCode(cssCode);
      setTimeout(() => {
        editorRef.current && editorRef.current.getAction('editor.action.formatDocument').run();
      }, 100)
    }
  // eslint-disable-next-line
  }, [visible, editorRef])

  const handleEditDidMount = (editor: any) => {
    editorRef.current = editor;
  };

  const handleEditorChange1 = useMemo(() => debounce((value: string) => {
    dispatch.page.handleEditCssCode({meta, value});
  }, 2000), [meta, dispatch])

  const handleEditorChange = (value: string) => {
    handleEditorChange1(value);
    setCode(value);
  }
  
  return (
    <>
      <div style={{display: 'flex'}}>
        <Button style={{flex: 1, margin: '0 24px'}} onClick={() => setVisible(true)}>样式源码</Button>
      </div>
      <Drawer
        title='样式源码'
        placement='right'
        onClose={() => setVisible(false)}
        visible={visible}
        getContainer={false}
        style={{ position: 'absolute' }}
        width={500}
        mask={false}
        className='code-edit-drawer'
      >
        <MonacoEditor
          language="css"
          value={code}
          options={
            {
              selectOnLineNumbers: true,
              // @ts-ignore
              renderSideBySide: false,
              formatOnType: true,
              minimap: {
                enabled: false
              }
            }
          }
          editorDidMount={handleEditDidMount}
          onChange={handleEditorChange}
        />
      </Drawer>
    </>
  );
}

export default React.memo(CssCodeEdit);