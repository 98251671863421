import React, { useMemo } from 'react';
import { Meta } from 'src/lib/type/meta';
import ColorPicker from '../colorPicker';
import InputSetting from '../inputSetting';
import settingFormItemHoc from '../settingFormItemHoc';
import SettingsGroup from '../settingsGroup';

interface Props {
  meta: Meta;
	cssRule: any;
  onChange: any;
}


const Backgroung = settingFormItemHoc((props: Props) => {
  const { cssRule, meta, onChange } = props;
  const linkValue = useMemo(() => {
    const styleStr = cssRule?.style?.['background-image'] || '';
    const urlArr = (styleStr.match(/((?<!\\)['"`])(.*?)\1/g) || []).map((item: string) => item.slice(1, -1));
    return urlArr[0] || '';
  }, [cssRule]);
  return (
    <SettingsGroup title='背景'>
      <ColorPicker title='背景颜色' type='background-color' cssRule={cssRule} meta={meta}/>
      <InputSetting value={linkValue} title='图片链接' onChange={(val: string) => onChange('background-image', `url("${decodeURIComponent(val)}")`)} />
    </SettingsGroup>
  );
})

export default Backgroung;