import React from 'react';
import { Provider } from "react-redux";
import store from './model';
import BuildMain from './main';
import BuildHeader from './components/header';
import './index.less';

const BuildPage = () => {
  return (
    <Provider store={store}>  
      <div className='container'>
        <BuildHeader />
        <div className='content'>
          <BuildMain/>
        </div>
      </div>
    </Provider>
  );
};

export default BuildPage;