import React from 'react';
import activeHOC from 'src/pages/build/hoc/activeHOC';
import { Meta } from '../../../../../lib/type/meta';
import styles from './index.module.less';

const Image: React.FC<{meta: Meta}> = activeHOC((props: any) => {
  const { handleClick, handleMouseOver, handleMouseOut, meta } = props;
  return (
    <>
      { !meta?.value?.src && <div onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`${styles['container-tips']} ${meta?.className}`}>添加图片</div> }
      { meta?.value?.src && <img onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={meta?.className || ''} src={meta.value.src} alt={meta.value.alt || ''}/>}
    </>
  );
});

export default Image;
