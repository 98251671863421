import React, { useMemo } from 'react';
import { Select, Input } from 'antd';
import '../../index.less';
import { Meta } from 'src/lib/type/meta';
import settingFormItemHoc from '../settingFormItemHoc';
import cssCore from 'src/pages/build/utils/cssCore';
import { isNumber } from 'src/pages/build/utils/common';

const Option = Select.Option;

enum UNIT {
	px = 'px',
	percentage = '%',
	auto = 'auto'
}

export enum SIZE_TYPE {
	width = 'width',
	height = 'height',
	fontSize = 'font-size',
	lineHeight = 'line-height',
	marginTop = 'margin-top',
	marginLeft = 'margin-left',
	marginRight = 'margin-right',
	marginBottom = 'margin-bottom',
	paddingTop = 'padding-top',
	paddingLeft = 'padding-left',
	paddingRight = 'padding-right',
	paddingBottom = 'padding-bottom',
	borderWidth = 'border-width',
	borderTopWidth = 'border-top-width',
	borderBottomWidth = 'border-bottom-width',
	borderLeftWidth = 'border-left-width',
	borderRightWidth = 'border-right-width'
}

const SIZE_TITLE = {
	[SIZE_TYPE.width]: '宽度',
	[SIZE_TYPE.height]: '高度',
	[SIZE_TYPE.fontSize]: '字体大小',
	[SIZE_TYPE.lineHeight]: '行间距',
	[SIZE_TYPE.marginTop]: '上边距',
	[SIZE_TYPE.paddingTop]: '上边距',
	[SIZE_TYPE.marginLeft]: '左边距',
	[SIZE_TYPE.paddingLeft]: '左边距',
	[SIZE_TYPE.marginRight]: '右边距',
	[SIZE_TYPE.paddingRight]: '右边距',
	[SIZE_TYPE.marginBottom]: '下边距',
	[SIZE_TYPE.paddingBottom]: '下边距',
	[SIZE_TYPE.borderWidth]: '边框宽',
	[SIZE_TYPE.borderTopWidth]: '边框宽',
	[SIZE_TYPE.borderBottomWidth]: '边框宽',
	[SIZE_TYPE.borderLeftWidth]: '边框宽',
	[SIZE_TYPE.borderRightWidth]: '边框宽',
}

const UNIT_LIST = [
	{
		key: UNIT.px,
		title: 'px'
	},
	{
		key: UNIT.percentage,
		title: '%'
	},
	{
		key: UNIT.auto,
		title: 'auto'
	}
]

// const isWidthAndHeight = (type: SIZE_TYPE) => type === SIZE_TYPE.width || type === SIZE_TYPE.height;

interface Props {
	type: SIZE_TYPE;
	meta: Meta;
	onChange: any;
	cssRule: any;
	otherUnit?: any[];
};

const SizeSetting = settingFormItemHoc((props: Props) => {
	const { type, onChange, cssRule, meta, otherUnit } = props;
	const style = useMemo(() => cssRule?.style?.[cssCore.camelize(type)], [type, cssRule]);
	const unit = useMemo(() => {
		if (style) return cssCore.getCssLengthUnit(style);
		return 'px';
	}, [style]);

	const lengthVal = useMemo(() => {
		if (style) return isNaN(parseFloat(style)) ? '' : parseFloat(style) + ''
		return '';
	}, [style]);

	const lengthPlaceholder = useMemo(() => {
		const currLen = cssCore.getStyle(meta?.className, type);
		return isNaN(parseFloat(currLen)) ? '' : parseFloat(currLen) + '';
	}, [meta, type]);
	

	const handleUnitChange = (val: any) => {
		let currLen: any = parseFloat(cssCore.getStyle(meta?.className, type));
		if (val === UNIT.px) {
			onChange(type, `${currLen}px`);
		} else if (val === UNIT.percentage) {
			const parentDom = cssCore.getDom(meta?.className)?.parentElement;
			const parentType = (type === SIZE_TYPE.width || type === SIZE_TYPE.height) ? type : SIZE_TYPE.width;
			const parentLen = parentDom?.getBoundingClientRect()?.[parentType] as any as string;
			if (!parentLen || !currLen) {
				onChange(type, '100%');
			} else {
				const n = parseFloat(currLen) / parseFloat(parentLen);
				onChange(type, `${(n > 1 ? 1 : n) * 100}%`);
			}
		} else if (val === UNIT.auto) {
			onChange(type, UNIT.auto);
		} else {
			onChange(type, currLen);
		}
	}

	const handleNumberChange = (e: any) => {
		const val = e.target.value;
		if (!val) return onChange(type, `0${unit}`);
		if (!isNumber(val)) return;
		onChange(type, `${val}${unit}`);
	}

	const selectAfter = (
		<Select value={unit} onChange={handleUnitChange}>
			{
				UNIT_LIST.concat(otherUnit || []).map(item => <Option key={item.key} value={item.key}>{item.title}</Option>)
			}
		</Select>
	);
	return (
		<div className='propsform-item-container'>
			<div className='propsform-item-title'>{SIZE_TITLE[type]}</div>
			<Input onChange={handleNumberChange} disabled={unit === UNIT.auto} value={lengthVal} addonAfter={selectAfter} placeholder={lengthPlaceholder}/>
		</div>
	);
});

export default SizeSetting;
