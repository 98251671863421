import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Meta } from 'src/lib/type/meta';
import InputSetting from 'src/pages/build/components/propsForm/components/inputSetting';
import { Dispatch } from 'src/pages/build/model';
// import commonStyles from '../../index.module.less';

interface Props {
	meta: Meta;
}

const BaseSettings: React.FC<Props> = ({meta}) => {
	const dispatch = useDispatch<Dispatch>();

	const handleImageChange = useCallback((value: string, type: string) => {
		dispatch.page.handleUpdateMeta({
			meta,
			value: {
				...(meta?.value || {}),
				[type]: value
			}
		})
	}, [meta, dispatch])

	return (
		<div>
			<InputSetting value={meta?.value?.src} title='图片链接' onChange={(value: string) => handleImageChange(value, 'src')} />
			<InputSetting value={meta?.value?.alt} title='图片描述' onChange={(value: string) => handleImageChange(value, 'alt')} />
		</div>
	);
}

export default BaseSettings;