import React, { useMemo } from 'react';
import ContainerStatus from 'src/pages/build/components/propsForm/components/containerStatus';
import SizeSetting, {SIZE_TYPE} from 'src/pages/build/components/propsForm/components/sizeSetting';
import { Meta } from 'src/lib/type/meta';
import cssCore from 'src/pages/build/utils/cssCore';
import CssCodeEdit from 'src/pages/build/components/propsForm/components/cssCodeEdit';
import DisplaySetting from 'src/pages/build/components/propsForm/components/displaySetting';

interface Props {
	widgetType: string;
	meta: Meta;
}

const StyleSettings: React.FC<Props> = ({meta}) => {
	const cssRule = useMemo(() => cssCore.getCssRule(meta.styleSheet, cssCore.formatClassName(meta.className, 'default')), [meta]);

	return (
		<div>
			<ContainerStatus meta={meta} />
			<SizeSetting cssRule={cssRule} meta={meta} type={SIZE_TYPE.width}/>
			<SizeSetting cssRule={cssRule} meta={meta} type={SIZE_TYPE.height}/>
			
			<DisplaySetting meta={meta} />
			<CssCodeEdit meta={meta} sheet={meta.styleSheet}/>
		</div>
	);
}

export default StyleSettings;