import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, message } from 'antd';
import { Dispatch, RootState } from '../../model';
import AddModal from './addModal';
import style from './index.module.less';
import BuildContent from '../content';

const { TabPane } = Tabs;

const ROOT_PATH = '/';

const RouterTabs = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const dispatch = useDispatch<Dispatch>();
  const {routers, activePath} = useSelector((state: RootState) => {
    return state.router;
  });

  const onEdit = (targetKey: any, action: 'add' | 'remove') => {
    if (action === 'add') {
      handleAdd();
    } else {
      handleRemove(targetKey);
    }
  };

  const handleAdd = () => {
    setModalVisible(true);
  }

  const handleRemove = (targetKey: any) => {
    if (targetKey === ROOT_PATH) return message.error('主路由无法删除！');
    dispatch.router.remove(targetKey);
  }

  const handleModalAdd = (params: {name: string, path: string}) => {
    const {name, path} = params;
    for (let i = 0; i < routers?.length; i++) {
      const router: any = routers[i];
      if (router.title === name) return message.error('路由名称已存在');
      if (router.key === path) return message.error('路由路径已存在');
    }
    dispatch.router.add({title: name, content: name, key: path});
    setModalVisible(false);
  }

  return (
    <div >
      <Tabs type="editable-card" className={style['tab-container']} onChange={(key: string) => dispatch.router.activeRouter(key)} activeKey={activePath} onEdit={onEdit}>
        {routers.map(router => (
          <TabPane tab={router.title} key={router.key}>
            <BuildContent />
          </TabPane>
        ))}
      </Tabs>
      <AddModal visible={modalVisible} onAdd={handleModalAdd} onCancel={() => setModalVisible(false)}/>
    </div>
  );
}

export default RouterTabs;