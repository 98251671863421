import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import CanvasContent from '../canvasContent';
import PropsForm from '../propsForm';
import style from './index.module.less';
import LeftMenu from '../leftMenu';

const BuildContent = () => {
  return (
    <DndProvider backend={ HTML5Backend }>
      <div className={style['container']}>
        <LeftMenu />
        <CanvasContent />
        <PropsForm />
      </div>
    </DndProvider>
  );
};

export default BuildContent;
