import React from 'react';
import { Select } from 'antd';
import '../../index.less';
import { Meta } from 'src/lib/type/meta';
import settingFormItemHoc from '../settingFormItemHoc';

const Option = Select.Option;

enum STATUS {
	default = 'default',
	hover = ':hover',
	active = ':active',
	focus = ':focus'
}

const statusOptions = [
	{
		key: STATUS.default,
		title: '默认状态'
	},
	{
		key: STATUS.hover,
		title: 'hover'
	},
	{
		key: STATUS.active,
		title: 'active'
	},
	{
		key: STATUS.focus,
		title: 'focus'
	},
]

interface Props {
	meta: Meta
}

const ContainerStatus: React.FC<Props> = settingFormItemHoc((props: Props) => {
	return (
		<div className='propsform-item-container'>
			<div className='propsform-item-title'>状态</div>
			<Select
				className='propsform-item-input'
			>
				{
					statusOptions.map((item: {key: STATUS, title: string}) => (
						<Option key={item.key}>{item.title}</Option>
					))
				}
			</Select>
		</div>
	);
});

export default ContainerStatus;
