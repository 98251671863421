import React, { useCallback, useEffect, useMemo, useState } from 'react'
// @ts-ignore
import { ColorResult, SketchPicker, RGBColor } from 'react-color';
import { Meta } from 'src/lib/type/meta';
import { isString } from 'src/pages/build/utils/common';
import cssCore from 'src/pages/build/utils/cssCore';
import  '../../index.less';
import settingFormItemHoc from '../settingFormItemHoc';

interface Props {
	title: string;
  type: string;
	meta: Meta;
	onChange: any;
	cssRule: any;
};

const rgbEqual = (rgb1: RGBColor, rgb2: RGBColor) => {
  if (Number(rgb1?.r) !== Number(rgb2?.r)) return false;
  if (Number(rgb1?.g) !== Number(rgb2?.g)) return false;
  if (Number(rgb1?.b) !== Number(rgb2?.b)) return false;
  if (Number(rgb1?.a ?? 1) !== Number(rgb2?.a ?? 1)) return false;
  return false;
}

const ColorPicker = settingFormItemHoc((props: Props) => {
  const { title, cssRule, type, onChange } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const camelizeType = useMemo(() => cssCore.camelize(type), [type])
  const colorValue = useMemo(() => {
    if (cssRule?.style?.[camelizeType]) {
      const value = cssRule?.style?.[camelizeType];
      const isrgb = value.indexOf('rgb') === 0;
      if (!isrgb) return value;
      const index = value.indexOf('(');
      const colors = value.slice(index + 1, -1).split(',');
      return {r: Number(colors[0].trim()), g: Number(colors[1].trim()), b: Number(colors[2].trim()), a: colors[3] ? Number(colors[3].trim()) : 1};
    }
    return {r: 0, g: 0, b: 0, a: 1};
  }, [cssRule, camelizeType]);

  const handleColorChange = useCallback((color: ColorResult) => {
    const { rgb, hex } = color;
    const {r, g, b, a} = rgb;
    if (isString(colorValue) && hex === colorValue) return;
    if (!isString(colorValue) && rgbEqual(colorValue, rgb)) return;
    onChange(type, `rgba(${r}, ${g}, ${b}, ${a})`);
  }, [colorValue, onChange, type]);

  const handleStopPropagation = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    const hidden = (e: any) => Number(e.keyCode) === 27 && setVisible(false);
    document.addEventListener('keyup', hidden);
    return () => {
      document.removeEventListener('keyup', hidden);
    }
  }, [])

  return (
    <div className='propsform-item-container' style={{zIndex: '100'}}>
			<div className='propsform-item-title'>{title}</div>
      <div onClick={() => setVisible(!visible)} className={`ant-input ${visible ? 'pirker-input-focus' : ''} picker-input`}>
        {cssRule?.style?.[camelizeType] ? (
          <div className='picker-color'>
            <div className='picker-color-block' style={{background: cssRule?.style?.[type]}}/>
            <div>{cssRule?.style?.[camelizeType]}</div>
          </div>
        ) : <div className='picker-placeholder'>请选择颜色</div>}
        { visible && (
          <div className='picker-content' onClick={handleStopPropagation}>
            <SketchPicker color={colorValue} onChange={handleColorChange}/>
          </div>
        )}
      </div>
    </div>
  );
})

export default ColorPicker;