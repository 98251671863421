import React, { useMemo } from 'react';
import { Meta } from 'src/lib/type/meta';
import { isNumber } from 'src/pages/build/utils/common';
import InputSetting from '../inputSetting';
import settingFormItemHoc from '../settingFormItemHoc';

interface Props {
	meta: Meta;
	onChange: any;
	cssRule: any;
};

const Opacity = settingFormItemHoc((props: Props) => {
  const { onChange, cssRule } = props;
	const value: any = useMemo(() => isNaN(Number(cssRule?.style?.opacity)) ? '' : Number(cssRule?.style?.opacity) * 100, [cssRule]);
  return (
    <InputSetting value={value} title='不透明度' placeholder='请填写0-100间整数' onChange={(val: string) => isNumber(val || 0) && onChange('opacity', (Number(val || 0) / 100).toFixed(2))} />
  );
});

export default Opacity;