import React from 'react';
import activeHOC from 'src/pages/build/hoc/activeHOC';
import { Meta } from '../../../../../lib/type/meta';

const Input: React.FC<{meta: Meta}> = activeHOC((props: any) => {
  const { handleClick, handleMouseOver, handleMouseOut } = props;
  return (
    <div onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className='container-tips'>input</div>
  );
});

export default Input;
