import React, { useMemo } from 'react';
import Choice from 'src/components/choice';
import { Meta } from 'src/lib/type/meta';
import settingFormItemHoc from '../settingFormItemHoc';

enum FONT_STYLE {
  italic = 'italic',
  normal = 'normal'
}

const DATA_LIST = [
  {
    key: FONT_STYLE.normal,
    title: FONT_STYLE.normal,
    desc: '正常'
  },
  {
    key: FONT_STYLE.italic,
    title: FONT_STYLE.italic,
    desc: '斜体'
  },
];

interface Props {
	meta: Meta;
	onChange: any;
	cssRule: any;
};

const FontStyle = settingFormItemHoc((props: Props) => {
  const { onChange, cssRule } = props;
	const value = useMemo(() => cssRule?.style?.fontStyle, [cssRule]);
  return (
    <div className='propsform-item-container'>
			<div className='propsform-item-title'>字体样式</div>
      <Choice data={DATA_LIST} onChange={(val: string) => onChange('font-style', val)} value={value || ''}/>
		</div>
  );
});

export default FontStyle;