import { Type, WidgetType } from "../../../lib/type/widget";

export interface Widget {
  title: {
    'zh-CN': string;
    'en-US': string;
  },
  widgetType: WidgetType;
  type: Type
}

export const widgetList: Widget[] = [
  {
    title: {
      'zh-CN': '容器',
      'en-US': 'Container'
    },
    widgetType: WidgetType.container,
    type: Type.CONTAINER,
  },
  {
    title: {
      'zh-CN': '图片',
      'en-US': 'Image'
    },
    widgetType: WidgetType.image,
    type: Type.FIELD,
  },
  {
    title: {
      'zh-CN': '文字',
      'en-US': 'Text'
    },
    widgetType: WidgetType.text,
    type: Type.FIELD,
  },
  {
    title: {
      'zh-CN': '单行输入框',
      'en-US': 'Input'
    },
    widgetType: WidgetType.input,
    type: Type.FIELD,
  },
];
