import { Select } from 'antd';
import React, { useState } from 'react';
import SettingsGroup from '../settingsGroup';
import SizeSetting from '../sizeSetting';
import { BORDER_RADIUS_OPTIONS, BORDER_RADIUS_TYPE, Props } from './common';
import '../../index.less';

const { Option } = Select;

const BorderRadius = (props: Omit<Props, 'onChange'>) => {
  const { meta, cssRule } = props;
  const [type, setType] = useState<BORDER_RADIUS_TYPE>(BORDER_RADIUS_TYPE.all)
  return (
    <SettingsGroup title='圆角'>
      <BorderType value={type} onChange={(val: BORDER_RADIUS_TYPE) => setType(val)}/>
      <SizeSetting cssRule={cssRule} meta={meta} type={type === BORDER_RADIUS_TYPE.all ? 'border-radius' : `border-${type}-radius`}/>
    </SettingsGroup>
  );
}

const BorderType = (props: {value: BORDER_RADIUS_TYPE, onChange: any}) => {
  const { value, onChange } = props;
  return (
    <div className='propsform-item-container'>
      <div className='propsform-item-title'>选择圆角</div>
      <Select
        className='propsform-item-input'
        value={value}
        onChange={onChange}
      >
        {
          BORDER_RADIUS_OPTIONS.map((item: {key: BORDER_RADIUS_TYPE, title: string}) => (
            <Option key={item.key}>{item.title}</Option>
          ))
        }
      </Select>
    </div>
  );
}

export default BorderRadius;