import { createModel } from '@rematch/core'
import { Meta } from '../../../lib/type/meta';
import { WidgetType } from '../../../lib/type/widget';
import { Widget, widgetList } from '../const/widget';
import { RootModel } from './models';
import widgets from '../components/widgets';
import cssCore from '../utils/cssCore';

const { sheet: defaultStyleSheet, element: styleDom } = (document as any).createStyleSheet();
const rootClassName = cssCore.createClassName('root');
const defaultRootCssText = `.${rootClassName} {
  padding: 0;
  position: 'relative';
  overflow: 'auto';
  background: '#fff';
}`

const rootContainer = {
  widgetType: WidgetType.container,
  id: 'root',
  key: 'root',
  event: {},
  fetch: {},
  customCode: '',
  children: [],
  parentId: '',
  className: rootClassName,
  style: {},
  styleSheet: defaultStyleSheet,
  styleDom
};
interface IState {
  widgetList: Widget[];
  activeWidget: string;
  hoverComponent: string;
  pageMetaMap: {[key: string]: Meta},
  widgets: {[key: string]: any},
  styleSheet: StyleSheet;
}

const page = createModel<RootModel>()({
  state: {
    widgetList: widgetList,
    activeWidget: '',
    hoverComponent: '',
    pageMetaMap: {
      root: rootContainer
    },
    widgets: widgets,
    styleSheet: defaultStyleSheet
  } as IState,
  reducers: {
    setState: (state, params = {}) => {
      return {
        ...state, 
        ...params
      }
    },
    setRootField: (state, {pageWidth, pageHeight}) => {
      const { styleSheet, styleDom } = state.pageMetaMap.root;
      const cssRule: any = cssCore.getCssRule(styleSheet, `.${state.pageMetaMap.root.className}`);
      const cssText = cssCore.replaceCssTextItem(cssRule?.cssText || defaultRootCssText, {width: pageWidth + 'px', height: pageHeight + 'px'})
      cssCore.insertCssRule(cssText, `.${rootClassName}`, styleSheet, styleDom);
      return {  ...state };
    },
    addField: (state, {meta, parent}) => {
      if (!meta) return state;
      const oldParent = state.pageMetaMap[parent.id];
      const newPageMetaMap = {
        ...state.pageMetaMap,
        [meta.id]: meta,
        [parent.id]: {
          ...oldParent,
          children: [...(oldParent.children), meta.id]
        }
      }
      return {
        ...state,
        pageMetaMap: newPageMetaMap
      };
    },
    handleChangeMetaStyle: (state, {meta, name, value, status}) => {
      // console.log(meta, name, value, status, 'handleChangeMetaStyle');
      const { styleSheet, className, styleDom } = meta;
      const newClassName = cssCore.formatClassName(className, status);
      const cssRule: any = cssCore.getCssRule(styleSheet, newClassName);
      const newCssText = cssCore.replaceCssTextItem(cssRule?.cssText || `${newClassName} {}`, {[name]: value});
      cssCore.insertCssRule(newCssText, cssCore.formatClassName(className, status), styleSheet, styleDom);
      const newSheet = styleDom.sheet;
      const newPageMetaMap = {
        ...state.pageMetaMap,
        [meta.id]: {
          ...meta,
          styleSheet: newSheet
        },
      }
      return { ...state, pageMetaMap: newPageMetaMap };
    },
    handleEditCssCode: (state, {meta, value}) => {
      meta.styleDom.innerHTML = value;
      const newSheet = meta.styleDom.sheet;
      const newPageMetaMap = {
        ...state.pageMetaMap,
        [meta.id]: {
          ...meta,
          styleSheet: newSheet
        },
      }
      return { ...state, pageMetaMap: newPageMetaMap };
    },
    handleUpdatePageMetaMapSort: (state, playlod) => {
      const { newPid, oldPid, newIndex, oldIndex,key } = playlod;
      const oldPageMetaMap = state.pageMetaMap;
      const oldParent: any = oldPageMetaMap[oldPid];
      const newParent: any = oldPageMetaMap[newPid];
      let newChildren = [...(newParent.children || [])];
      const item = oldPageMetaMap[key];
      const newPageMetaMap: any = {
        ...oldPageMetaMap,
        [key]: {
          ...item,
          parentId: newPid
        }
      }
      if (newPid === oldPid) {
        const i = newChildren.splice(oldIndex, 1);
        newChildren.splice(newIndex, 0, i[0]);
      } else {
        newChildren.splice(newIndex, 0, key);
        newPageMetaMap[oldPid] = {
          ...oldParent,
          children: (oldParent.children || []).filter((item: string) => item && item !== key)
        };
      }
      newPageMetaMap[newPid] = {
        ...newParent,
        children: newChildren
      }
      return {
        ...state,
        pageMetaMap: newPageMetaMap
      };
    },
    handleUpdateMeta: (state, playlod) => {
      const {meta, ...args} = playlod;
      const newPageMetaMap = {
        ...state.pageMetaMap,
        [meta.id]: {
          ...meta,
          ...args
        },
      };
      return { ...state, pageMetaMap: newPageMetaMap };
    }
  },
  effects: (dispatch) => ({
    addFieldEffect(playlod: any) {
      const {origin, parent} = playlod;
      const meta = widgets[origin.widgetType].createFieldMeta();
      meta.parentId = parent.id;
      dispatch.page.addField({meta, parent});
      setTimeout(() => {
        dispatch.page.setState({
          activeWidget: meta.id,
        })
      }, 50)
    }
  })
})

export default page;