import React from 'react';
import commonStyles from '../../index.module.less';

interface Props {
	widgetType: string;
}

const BaseSettings: React.FC<Props> = () => {
	return (
		<div>
			<div className={commonStyles['propsform-title']} >属性</div>
		</div>
	);
}

export default BaseSettings;