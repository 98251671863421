import { Select } from 'antd';
import React, { useMemo } from 'react';
import { Meta } from 'src/lib/type/meta';
import settingFormItemHoc from '../settingFormItemHoc';

const { Option } = Select;

export interface Props {
	meta: Meta;
  onChange: any;
	cssRule: any;
}

const CURSOR_LIST = [
  {
    key: 'default',
    title: '默认'
  },
  {
    key: 'pointer',
    title: '点击'
  }
]

const Cursor = settingFormItemHoc((props: Props) => {
  const { cssRule, onChange } = props;
  const value = useMemo(() => cssRule?.style?.cursor || 'default', [cssRule]);
  return (
    <div className='propsform-item-container'>
      <div className='propsform-item-title'>鼠标手势</div>
      <Select
        className='propsform-item-input'
        value={value}
        onChange={(val: string) => onChange('cursor', val)}
      >
        {
          CURSOR_LIST.map((item: {key: string, title: string}) => (
            <Option key={item.key}>{item.title}</Option>
          ))
        }
      </Select>
    </div>
  );
});

export default Cursor;