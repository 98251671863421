import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../model';
import widgets from '../widgets';
import style from './index.module.less';

const CanvasDrop = () => {
  const { pageMetaMap } = useSelector((state: RootState) => {
    return state.page;
  });
  const { pageWidth, pageHeight } = useSelector((state: RootState) => {
    return state.router;
  });

  const { Component: RootComponent } = widgets[pageMetaMap.root.widgetType];

  return (
    <div className={style['content']} style={{width: `${pageWidth}px`, height: `${pageHeight}px`}}>
      <RootComponent meta={pageMetaMap.root}/>
    </div>
  );
}

const CanvasContent = () => {
  const dispatch = useDispatch<Dispatch>();
  const { pageWidth, pageHeight } = useSelector((state: RootState) => {
    return state.router;
  });

  useEffect(() => {
    const clearActive = () => dispatch.page.setState({activeWidget: ''});
    document.body.addEventListener('click', clearActive);
    return () => {  
      document.body.removeEventListener('click', clearActive);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch.page.setRootField({pageWidth, pageHeight});
  }, [pageWidth, pageHeight, dispatch]);

  return (
    <div className={style['canvas-container']} id='canvas-container'>
      <CanvasDrop />
    </div>
  );
}

export default CanvasContent;
