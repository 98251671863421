import React from 'react';
import { ConfigProvider } from 'antd';
import RouterTabs from '../components/routerTabs'
import style from './index.module.less';
import 'antd/dist/antd.min.css';

const BuildMain = () => {
  return (
    <ConfigProvider>
      <div className={style.container}>
        <RouterTabs />
      </div>
    </ConfigProvider>
  );
}

export default BuildMain;