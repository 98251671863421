import React, { useState } from 'react';
import { Menu, Drawer } from 'antd';
import type { MenuProps } from 'antd';
import WidgetList from '../widgetList';
import styles from './index.module.less';
import ComponentsTree from '../componentsTree';

enum MEUN_TYPE {
  widgetList = 'widget',
  componentsTree = 'components',
  eventSource = 'event',
  variabled = 'variabled'
}

const items: MenuProps['items'] = [
  {
    label: '大纲树',
    key: MEUN_TYPE.componentsTree,
  },
  {
    label: '组件库',
    key: MEUN_TYPE.widgetList,
  },       
  {
    label: '事件',
    key: MEUN_TYPE.eventSource,
  },    
  {
    label: '变量',
    key: MEUN_TYPE.variabled,
  }, 
];

const LeftMenu = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [active, setActive] = useState<any>(null);

  const handleCloseDrawer = () => {
    setVisible(false);
    setActive(null);
  }

  const handleSelectMenu = (item: any) => {
    setVisible(true);
    setActive(item);
  }

  return (
    <div className={styles['left-container']}>
      <Menu style={{width: '90px'}} selectedKeys={[active?.key].filter(Boolean)} items={items} onSelect={handleSelectMenu}/>
      <Drawer
        title={active?.label || ''}
        placement='left'
        onClose={handleCloseDrawer}
        visible={visible}
        getContainer={false}
        style={{ position: 'absolute' }}
        width={400}
        mask={false}
        className='code-edit-drawer'
      >
        {active?.key === MEUN_TYPE.widgetList && <WidgetList />}
        {active?.key === MEUN_TYPE.componentsTree && <ComponentsTree />}
      </Drawer>
    </div>
    
  );
}

export default LeftMenu;