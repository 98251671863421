import { Tree } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../model';

const ComponentsTree = () => {
  const dispatch = useDispatch<Dispatch>();
  const { pageMetaMap } = useSelector((state: RootState) => {
    return state.page;
  });

  const treeFormat = (root: any, callback: any) => {
    const stack = [];
    stack.push(root);
    while(stack.length) {
      const item: any = stack.shift();
      callback(item, stack)
    }
    return [root];
  }

  const treeData: any[] = useMemo(() => {
    const root: any = Object.assign({}, pageMetaMap.root);
    root.classNameCopy = root.className;
    root.className = '';
    return treeFormat(root, (item: any, stack: any[]) => {
      const children = item?.children || [];
      const newChildren: any = [];
      item.children = newChildren;
      for (let i = 0; i < children.length; i++) {
        const key = children[i];
        const data: any = {...(pageMetaMap[key] || {})};
        if (data?.children?.length) stack.push(data);
        data.classNameCopy = data.className;
        data.className = '';
        item.children.push(data);
      }
    });
  }, [pageMetaMap]);

  const onDrop: any = useCallback((info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    let newPid: string = '', oldPid: string = '', newIndex: number = -1, oldIndex: number = -1;

    const loop = (
      data: any[],
      key: React.Key,
      callback: (node: any, i: number, data: any[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback({...data[i]}, i, data);
        }
        if (data[i].children) {
          loop([...data[i].children], key, callback);
        }
      }
    };
    const data = [...treeData];

    // Find dragObject
    loop(data, dragKey, (item, index, arr) => {
      oldIndex = index;
      oldPid = item.parentId;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        newPid = item.id;
        newIndex = 0;
      });
    } else if (
      ((info.node as any).props.children || []).length > 0 && // Has children
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        newPid = item.id;
        newIndex = 0;
      });
    } else {
      let i: number;
      loop(data, dropKey, (_item, index: number, arr) => {
        i = index;
        newPid = arr[0]?.parentId;
      });
      if (dropPosition === -1) {
        newIndex = i!;
      } else {
        newIndex = i! + 1;
      }
    }
    dispatch.page.handleUpdatePageMetaMapSort({newPid, oldPid, newIndex, oldIndex, key: dragKey});
  }, [treeData, dispatch]);

  const handleActiveItem = (e: any, id: string) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch.page.setState({activeWidget: id});
  }

  const renderTreeItem = (node: any) => {
    return <div onClick={(e: any) => handleActiveItem(e, node?.id)}>{node?.widgetType}</div>
  }

  const handleDraggable = (node: any) => {
    return node.id !== 'root';
  }

  return (
    <div style={{paddingTop: '20px'}}>
      <Tree
        className="draggable-tree"
        draggable={handleDraggable}
        blockNode
        onDrop={onDrop}
        treeData={treeData}
        titleRender={renderTreeItem}
      />
    </div>
  );
}

export default ComponentsTree;