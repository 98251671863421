import React from 'react';
import activeHOC from 'src/pages/build/hoc/activeHOC';
import { Meta } from '../../../../../lib/type/meta';
import styles from './index.module.less';

const Image: React.FC<{meta: Meta}> = activeHOC((props: any) => {
  const { handleClick, handleMouseOver, handleMouseOut, meta } = props;
  return (
    <>
      { !meta?.value && <div onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`${styles['container-tips']} ${meta?.className}`}>添加文字</div> }
      { meta?.value && <div onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={meta?.className || ''} >{meta?.value}</div>}
    </>
  );
});

export default Image;
