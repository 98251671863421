export const throttle = (callback: (...params: any) => any, time: number) => {
  let timer: any = null;
  return (...params: any) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      callback(...params);
    })
  }
}

export const createFieldId = () => {
  const time = + new Date();
  return `field_${time}`
};

export const isNumber = (item: any) => {
  const num = Number(item);
  if (item === 0) return true;
  if (!item || isNaN(num)) return false;
  return true;
}

export const isString = (s: any) => {
  return Object.prototype.toString.call(s) === '[object String]'
}