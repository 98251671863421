import React, { useMemo } from 'react';
import Choice from 'src/components/choice';
import { Meta } from 'src/lib/type/meta';
import settingFormItemHoc from '../settingFormItemHoc';

enum FONT_STYLE {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify'
}

const DATA_LIST = [
  {
    key: FONT_STYLE.left,
    title: FONT_STYLE.left,
    desc: FONT_STYLE.left
  },
  {
    key: FONT_STYLE.center,
    title: FONT_STYLE.center,
    desc: FONT_STYLE.center
  },
  {
    key: FONT_STYLE.right,
    title: FONT_STYLE.right,
    desc: FONT_STYLE.right
  },
  {
    key: FONT_STYLE.justify,
    title: FONT_STYLE.justify,
    desc: FONT_STYLE.justify
  },
];

interface Props {
	meta: Meta;
	onChange: any;
	cssRule: any;
};

const FontAlign = settingFormItemHoc((props: Props) => {
  const { onChange, cssRule } = props;
	const value = useMemo(() => cssRule?.style?.textAlign, [cssRule]);
  return (
    <div className='propsform-item-container'>
			<div className='propsform-item-title'>字体对齐</div>
      <Choice data={DATA_LIST} onChange={(val: string) => onChange('text-align', val)} value={value || ''}/>
		</div>
  );
});

export default FontAlign;