import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../model';
import cssCore from '../utils/cssCore';
import styles from './index.module.less';

const activeHOC = (Com: React.FC) => {
  return (props: any) => {
    const { meta } = props;
    const [positionX, setPositionX] = useState<number>(0);
    const [positionY, setPositionY] = useState<number>(0);
    const [domStyle, setDomStyle] = useState<any>({});
    const dispatch = useDispatch<Dispatch>();
    const hocRef = useRef<any>(null);
    const { activeWidget, hoverComponent, pageMetaMap } = useSelector((state: RootState) => {
      return state.page;
    });
    const isActive = useMemo(() => meta?.id === activeWidget, [meta, activeWidget]);
    const isHover = useMemo(() => meta?.id === hoverComponent, [meta, hoverComponent]);
    const [isDraging, setIsDraging] = useState<boolean>(false);
    // const domStyle = useMemo(() => cssCore.getStyle(meta?.className, ['width', 'height']), [meta]);

    useEffect(() => {
      setTimeout(() => {
        setDomStyle(cssCore.getStyle(meta?.className, ['width', 'height']) || {});
      })
    }, [meta])

    const handleActive = useCallback((e: any) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      dispatch.page.setState({activeWidget: meta?.id});
    }, [meta, dispatch])

    const handleHover = useCallback((e: any) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      dispatch.page.setState({hoverComponent: meta?.id});
    }, [meta, dispatch]);

    const handleHoverLeave = useCallback((e: any) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      hoverComponent === meta?.id && dispatch.page.setState({hoverComponent: ''});
    }, [hoverComponent, meta, dispatch])

    if (!meta) return null;

    // const cssRule: any = cssCore.getCssRule(meta.styleSheet, cssCore.formatClassName(meta.className, 'default'));

    useEffect(() => {
      if (isActive || isHover || isDraging) {
        const position = cssCore.getPosition(meta?.className, pageMetaMap[meta?.parentId] ? pageMetaMap[meta?.parentId]?.id : 'root');
        if (position) {
          setPositionX(position?.x ?? 0);
          setPositionY(position?.y ?? 0);
          
        }
      }
    }, [isActive, isHover, isDraging, meta, pageMetaMap])

    return (
      <>
        <div className={styles['active-container']} style={{width: `${domStyle['width'] ? domStyle['width'] : '100%'}`, height: `${domStyle['height'] ? domStyle['height'] : 'auto'}`, position: 'absolute', left: `${positionX}px`, top: `${positionY}px`, pointerEvents: 'none'}} ref={hocRef}>
          {(isActive || isHover || isDraging) && (
            <>
              <div className={`${styles['active-line']} ${styles['active-line-top']}`}></div>
              <div className={`${styles['active-line']} ${styles['active-line-right']}`}></div>
              <div className={`${styles['active-line']} ${styles['active-line-bottom']}`}></div>
              <div className={`${styles['active-line']} ${styles['active-line-left']}`}></div>
            </>
          )}
        </div>
        <Com {...props} onSetIsDraging={(status: boolean) => setIsDraging(status)} isActive={isActive} handleClick={handleActive} handleMouseOver={handleHover} handleMouseOut={handleHoverLeave}/>
      </>
    )
  }
}

export default activeHOC;
