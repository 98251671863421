import React, { useMemo } from 'react';
import { Select } from 'antd';
import '../../index.less';
import { Meta } from 'src/lib/type/meta';
import settingFormItemHoc from '../settingFormItemHoc';
import SettingsGroup from '../settingsGroup';
import Choice from 'src/components/choice';
import { alignItemsData, flexDerictionData, justifyContentData } from './static';

const Option = Select.Option;

enum STATUS {
	block = 'block',
	inlineBlock = 'inline-block',
	inline = 'inline',
	flex = 'flex'
}

const statusOptions = [
	{
		key: STATUS.block,
		title: '块'
	},
	{
		key: STATUS.inlineBlock,
		title: '行内块'
	},
	{
		key: STATUS.inline,
		title: '行内'
	},
	{
		key: STATUS.flex,
		title: '弹性'
	},
]

interface Props {
	meta: Meta;
  onChange: any;
	cssRule: any;
}

const DisplaySetting = settingFormItemHoc((props: Props) => {
  const { onChange, cssRule, meta } = props;
	const value = useMemo(() => cssRule?.style?.display || STATUS.block, [cssRule]);

  const handleChange = (e: any) => {
    onChange('display', e);
  }
	return (
		<>
			<div className='propsform-item-container'>
				<div className='propsform-item-title'>显示</div>
				<Select
					className='propsform-item-input'
					value={value}
					onChange={handleChange}
				>
					{
						statusOptions.map((item: {key: STATUS, title: string}) => (
							<Option key={item.key}>{item.title}</Option>
						))
					}
				</Select>
			</div>
			{
				value === STATUS.flex && <FlexGroup cssRule={cssRule} meta={meta}/>
			}
		</>
	);
});

const FlexGroup = (props: Omit<Props, "onChange">) => {
	return (
		<div>
			<SettingsGroup title='弹性布局' defaultOpen>
				<FlexDirection {...props}/>
				<AlignItems {...props} />
				<JustifyContent {...props}/>
			</SettingsGroup>
		</div>
	);
}

const FlexDirection = settingFormItemHoc((props: Props) => {
	const { cssRule, onChange } = props;
	const value = useMemo(() => cssRule?.style?.flexDirection, [cssRule]);
	return (
		<div className='propsform-item-container'><Choice data={flexDerictionData} onChange={(val: string) => onChange('flex-direction', val)} value={value || ''}/></div>
	);
})

const AlignItems = settingFormItemHoc((props: Props) => {
	const { cssRule, onChange } = props;
	const value = useMemo(() => cssRule?.style?.alignItems, [cssRule]);
	return (
		<div className='propsform-item-container'><Choice data={alignItemsData} onChange={(val: string) => onChange('align-items', val)} value={value || ''}/></div>
	);
})

const JustifyContent = settingFormItemHoc((props: Props) => {
	const { cssRule, onChange } = props;
	const value = useMemo(() => cssRule?.style?.justifyContent, [cssRule]);
	return (
		<div className='propsform-item-container'><Choice data={justifyContentData} onChange={(val: string) => onChange('justify-content', val)} value={value || ''}/></div>
	);
})

export default DisplaySetting;
