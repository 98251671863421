import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Meta } from '../../../../../lib/type/meta';
import activeHOC from '../../../hoc/activeHOC';
import { DragType } from '../../../const/drag';
import { Dispatch, RootState } from '../../../model';
import styles from './index.module.less';

const Container: React.FC<{meta: Meta, onSetIsDraging: (status: boolean) => void}> = activeHOC((props: any) => {
  const dispatch = useDispatch<Dispatch>();
  const { onSetIsDraging } = props;
  const { pageMetaMap, widgets } = useSelector((state: RootState) => {
    return state.page;
  });
  const {handleClick, handleMouseOver, handleMouseOut} = props;
  const [_, drop] = useDrop(() => ({
    accept: DragType.widget,
    drop: (collect: any, monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      if (!isOver) return;
      dispatch.page.addFieldEffect({
        origin: collect,
        parent: props.meta
      });
    },
    canDrop: (_, monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      return isOver;
    },
    collect(monitor) {
      const item = monitor.getItem();
      const isOver = monitor.isOver({ shallow: true });
      return {
        item,
        isOver
      };
    }
  }));
  const { className } = props?.meta || {};

  useEffect(() => {
    onSetIsDraging && onSetIsDraging(!!_?.isOver);
  }, [_?.isOver, onSetIsDraging])
  
  return (
    <div className={className} id={props?.meta?.id} ref={drop} onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {!props?.meta?.children?.length && <div className={styles['container-tips']}>拖拽组件或模板到这里</div>}
      {
        (props?.meta?.children || []).map((item: string) => {
          const fieldMeta = pageMetaMap[item];
          const { Component } = widgets[fieldMeta.widgetType];
          return <Component key={fieldMeta.id} meta={fieldMeta}/>
        })
      }
    </div>
  );
});

export default Container;
