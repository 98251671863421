import React, { useState } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../index.less';
import './index.less';

const SettingsGroup = (props: any) => {
  const [showMore, setShowMore] = useState<boolean>(!!props?.defaultOpen);

  return (
    <div className='propsform-item-container'>
			<div className='propsform-item-title' style={{cursor: 'pointer'}} onClick={() => setShowMore(!showMore)}>
        {
          showMore 
            ? <CaretUpOutlined style={{marginRight: '8px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)'}} />
            : <CaretDownOutlined style={{marginRight: '8px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)'}} />
        }
        {props.title}
      </div>
      {
        showMore && (
          <div className='settings-group-content'>
            {props.children}
          </div>
        )
      }
			
		</div>
  );
}

export default SettingsGroup;