import cssCore from 'src/pages/build/utils/cssCore';
import { WidgetType } from '../../../../../lib/type/widget';
import { createFieldId } from '../../../utils/common';
import Component from './component';
import * as PropsForm from './propsForm';

const config = {
  Component,
  PropsForm,
  createFieldMeta: () => {
    const className = cssCore.createClassName('container');
    const { sheet, element } = (document as any).createStyleSheet();
    const id = createFieldId();
    return {
      widgetType: WidgetType.container,
      id,
      key: id,
      event: {},
      fetch: {},
      customCode: '',
      children: [],
      className,
      styleSheet: sheet,
      styleDom: element
    };
  }
};

export default config;