import React from 'react';
import { BrowserRouter, Routes as ReactRoutes, Route, Outlet, useNavigate, Navigate } from "react-router-dom";
import BuildPage from './pages/build';
import HomePage from './pages/home';  
import {HistoryProvider} from './lib/context';

interface HistoryProps {
  history?: any;
}

const ContextHistory: React.FC<HistoryProps> = (props) => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Navigate to="/home" replace={true} /> */}
      <HistoryProvider value={{history: navigate}}>
        <Outlet />
      </HistoryProvider>
    </>
  );
}

const NotFount = () => {
  return <Navigate to="/" replace />;
}

function Routes() {
  return (
    <BrowserRouter >
      <ReactRoutes >
        <Route path="/" element={<ContextHistory />}>
          {/* <Route index element={<HomePage />}/> */}
          <Route index element={<BuildPage />}/> 
          <Route path="/build" element={<BuildPage />} />
          <Route path="*" element={<NotFount />} />
        </Route>
      </ReactRoutes>
    </BrowserRouter>
  );
}

export default Routes;
