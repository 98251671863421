export const fontData = [
  {
    title: 'Arial, Helvetica, 微软雅黑',
    key: 'arial, helvetica, "microsoft yahei"'
  },
  {
    title: 'Arial, Helvetica, 黑体',
    key: 'arial, helvetica, simhei'
  },
  {
    title: 'Comic Sans MS, 微软雅黑',
    key: '"comic sans ms", "microsoft yahei"'
  },
  {
    title: 'Comic Sans MS, 黑体',
    key: '"comic sans ms", simhei'
  },
  {
    title: 'Impact, 微软雅黑',
    key: 'impact, "microsoft yahei"'
  },
  {
    title: 'Impact, 黑体',
    key: 'impact, simhei'
  },
  {
    title: 'Lucida Sans Unicode, 微软雅黑',
    key: '"lucida sans unicode", "microsoft yahei"'
  },
  {
    title: 'Lucida Sans Unicode, 黑体',
    key: '"lucida sans unicode", simhei'
  },
  {
    title: 'Trebuchet MS, 微软雅黑',
    key: '"trebuchet ms", "microsoft yahei"'
  },
  {
    title: 'Trebuchet MS, 黑体',
    key: '"trebuchet ms", simhei'
  },
  {
    title: 'Verdana, 微软雅黑',
    key: 'verdana, "microsoft yahei"'
  },
  {
    title: 'Verdana, 黑体',
    key: 'verdana, simhei'
  },
  {
    title: 'Georgia, 微软雅黑',
    key: 'georgia, "microsoft yahei"'
  },
  {
    title: 'Georgia, 黑体',
    key: 'georgia, simhei'
  },
  {
    title: 'Palatino Linotype, 微软雅黑',
    key: '"palatino linotype", "microsoft yahei"'
  },
  {
    title: 'Palatino Linotype, 黑体',
    key: '"palatino linotype", simhei'
  },
  {
    title: 'Times New Roman, 微软雅黑',
    key: '"times new roman", "microsoft yahei"'
  },
  {
    title: 'Times New Roman, 黑体',
    key: '"times new roman", simhei'
  },
  {
    title: 'Courier New, 微软雅黑',
    key: '"courier new", "microsoft yahei"'
  },
  {
    title: 'Courier New, 黑体',
    key: '"courier new", simhei'
  },
  {
    title: 'Lucida Console, 微软雅黑',
    key: '"lucida console", "microsoft yahei"'
  },
  {
    title: 'Lucida Console, 黑体',
    key: '"lucida console", simhei'
  },
]

export const defaultFont = fontData[0];
