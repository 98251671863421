import { Tooltip } from 'antd';
import React from 'react';
import './index.less';

type DataItem = {
  title?: string;
  desc: string;
  key: string;
  svg?: any;
}

interface Props {
  data: DataItem[];
  value: string;
  onChange: any;
}

const Choice = (props: Props) => {
  const { data = [], value, onChange } = props;
  return (
    <div className='choice-container'>
      {
        data.map((item: DataItem) => (
          <Tooltip placement="top" title={item.desc} key={item.key}>
             <div onClick={() => onChange && onChange(item.key)} className={`choice-item ${value === item.key ? 'choice-item-active' : ''}`}>{item?.title ? item?.title : <item.svg />}</div>
          </Tooltip>
        ))
      }
    </div>
  );
};

export default Choice;
