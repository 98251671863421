export enum Type {
  CONTAINER = 1,
  FIELD = 2
}

export enum WidgetType {
  container = 'container',
  image = 'image',
  input = 'input',
  text = 'text'
}