import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import style from './index.module.less';

interface IProps {
  visible: boolean;
  onAdd: (params: any) => void;
  onCancel: () => void;
}

const AddModal: React.FC<IProps> = ({visible, onAdd, onCancel}) => {
  const [name, setName] = useState<string>('');
  const [path, setPath] = useState<string>(''); 

  useEffect(() => {
    if (!visible) {
      setName('');
      setPath('');
    }
  }, [visible]);

  return (
    <Modal title="添加路由" visible={visible} onOk={() => onAdd({name, path})} onCancel={onCancel}>
      <div className={style['module-form-item']}>
        <div className={style['module-form-item-label-require']}>路由名称</div>
        <Input value={name} onChange={e => setName(e.target.value)} placeholder="请填写路由名称" style={{width: '350px'}}/>
      </div>
      <div className={style['module-form-item']}>
        <div className={style['module-form-item-label-require']}>路由路径</div>
        <Input value={path} onChange={e => setPath(e.target.value)} placeholder="请填写路由路径，例：/admin/user" style={{width: '350px'}}/>
      </div>
    </Modal>
  );
}

export default AddModal;
