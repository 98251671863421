import React from 'react';
import { Meta } from 'src/lib/type/meta';
import SettingsGroup from '../settingsGroup';
import SizeSetting, { SIZE_TYPE } from '../sizeSetting';

const title: {[key: string]: string} = {
  margin: '外边距',
  padding: '内边距'
}

interface Props {
  meta: Meta;
	cssRule: any;
  type: 'margin' | 'padding';
}

const MarginPadding = (props: Props) => {
  const { type, cssRule, meta } = props;
  return (
    <SettingsGroup title={title[type]}>
      <SizeSetting cssRule={cssRule} meta={meta} type={SIZE_TYPE[`${type}Top`]}/>
      <SizeSetting cssRule={cssRule} meta={meta} type={SIZE_TYPE[`${type}Right`]}/>
      <SizeSetting cssRule={cssRule} meta={meta} type={SIZE_TYPE[`${type}Bottom`]}/>
      <SizeSetting cssRule={cssRule} meta={meta} type={SIZE_TYPE[`${type}Left`]}/>
    </SettingsGroup>
  );
}

export default MarginPadding;
