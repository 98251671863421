import React, { useMemo } from 'react';
import ContainerStatus from 'src/pages/build/components/propsForm/components/containerStatus';
import SizeSetting, {SIZE_TYPE} from 'src/pages/build/components/propsForm/components/sizeSetting';
import { Meta } from 'src/lib/type/meta';
import cssCore from 'src/pages/build/utils/cssCore';
import CssCodeEdit from 'src/pages/build/components/propsForm/components/cssCodeEdit';
import DisplaySetting from 'src/pages/build/components/propsForm/components/displaySetting';
import MarginPadding from 'src/pages/build/components/propsForm/components/marginPadding';
import Border from 'src/pages/build/components/propsForm/components/border';
import BorderRadius from 'src/pages/build/components/propsForm/components/border/borderRadius';
import Opacity from 'src/pages/build/components/propsForm/components/opacity';
import Cursor from 'src/pages/build/components/propsForm/components/cursor';

interface Props {
	widgetType: string;
	meta: Meta;
}

const StyleSettings: React.FC<Props> = ({meta}) => {
	const cssRule = useMemo(() => cssCore.getCssRule(meta.styleSheet, cssCore.formatClassName(meta.className, 'default')), [meta]);

	return (
		<div>
			<ContainerStatus meta={meta} />
			<SizeSetting cssRule={cssRule} meta={meta} type={SIZE_TYPE.width}/>
			<SizeSetting cssRule={cssRule} meta={meta} type={SIZE_TYPE.height}/>
			<MarginPadding type='margin' meta={meta} cssRule={cssRule}/> 
			<Border cssRule={cssRule} meta={meta} />
			<BorderRadius cssRule={cssRule} meta={meta} />
			<Opacity cssRule={cssRule} meta={meta} />
			<Cursor cssRule={cssRule} meta={meta} />
			<CssCodeEdit meta={meta} sheet={meta.styleSheet}/>
		</div>
	);
}

export default StyleSettings;