import React from 'react';
import commonStyles from '../../index.module.less';

interface Props {
	widgetType: string;
}

const AdvancedSettings: React.FC<Props> = () => {
	return (
		<div>
      <div className={commonStyles['propsform-title']} >高级设置</div>
    </div>
	);
}

export default AdvancedSettings;