import React, { useMemo } from 'react';
import Choice from 'src/components/choice';
import { Meta } from 'src/lib/type/meta';
import settingFormItemHoc from '../settingFormItemHoc';

enum FONT_STYLE {
  none = 'none',
  underline = 'underline',
  lineThrough = 'line-through'
}

const DATA_LIST = [
  {
    key: FONT_STYLE.none,
    title: '无',
    desc: FONT_STYLE.none
  },
  {
    key: FONT_STYLE.underline,
    title: '下划线',
    desc: FONT_STYLE.underline
  },
  {
    key: FONT_STYLE.lineThrough,
    title: '删除线',
    desc: FONT_STYLE.lineThrough
  },
];

interface Props {
	meta: Meta;
	onChange: any;
	cssRule: any;
};

const FontDecoration = settingFormItemHoc((props: Props) => {
  const { onChange, cssRule } = props;
	const value = useMemo(() => cssRule?.style?.textDecoration, [cssRule]);
  return (
    <div className='propsform-item-container'>
			<div className='propsform-item-title'>修饰</div>
      <Choice data={DATA_LIST} onChange={(val: string) => onChange('text-decoration', val)} value={value || ''}/>
		</div>
  );
});

export default FontDecoration;