import container from './container';
import image from './image';
import input from './input';
import text from './text';

export default {
  container,
  image,
  input,
  text
} as any