import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'src/pages/build/model';


const settingFormItemHoc = (Com: any) => {
  return React.memo((props: any = {}) => {
    const dispatch = useDispatch<Dispatch>();
    const handleSettingChange = (name: string, value: string) => {
      dispatch.page.handleChangeMetaStyle({meta: props.meta, name, value, status: 'default'});
    }
    return <Com {...props} onChange={handleSettingChange}/>
  })
}

export default settingFormItemHoc;