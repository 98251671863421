import { WidgetType } from 'src/lib/type/widget';
import { createFieldId } from 'src/pages/build/utils/common';
import cssCore from 'src/pages/build/utils/cssCore';
import Component from './component';
import * as PropsForm from './propsForm';

const config = {
  Component,
  PropsForm,
  createFieldMeta: () => {
    const className = cssCore.createClassName('text');
    const { sheet, element } = (document as any).createStyleSheet();
    const id = createFieldId();
    return {
      widgetType: WidgetType.text,
      id,
      key: id,
      event: {},
      fetch: {},
      customCode: '',
      children: [],
      className,
      styleSheet: sheet,
      styleDom: element,
      value: ''
    }
  }
};

export default config;